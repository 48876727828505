@media (min-width: 1200px) {
  .icon-title-icon {
    font-size: 3vh;
  }
  .icon-title-name {
    margin: 0;
    font-weight: 500;
    font-size: 1.5vh;
  }
  .icon-title-icon-img {
    width: 3vh;
    height: 3vh;
    object-fit: 'contain';
    flex: 1;
    image-rendering: -webkit-optimize-contrast;
  }
  .icon-title-container {
    margin-right: 2vw;
  }
}

@media (max-width: 1199px) {
  .icon-title-icon {
    font-size: 30px;
  }
  .icon-title-name {
    margin: 0;
    font-weight: 500;
  }
  .icon-title-icon-img {
    width: 30px;
    height: 30px;
    object-fit: 'contain';
    flex: 1;
    image-rendering: -webkit-optimize-contrast;
  }
  .icon-title-container {
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
