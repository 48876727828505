.landing-page-card-out {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15px;
  left: 40px;
  width: 0%;
  height: 100%;
  background-color: rgb(48, 66, 109);
  transition: width 0.25s ease-in-out;
  font-size: 50px;
  overflow: hidden;
  margin: 20px;
  transform: translateZ(55px);
}

.landing-page-card-in {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15px;
  left: 40px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 50px;
  background-color: rgb(48, 66, 109);
  transition: width 0.25s ease-in-out;
  margin: 20px;
  transform: translateZ(55px);
}

.landing-page-who-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 80%;
}

.landing-page-card-actual {
  transform: translateZ(50px);
}

@media (min-width: 1200px) {
  .landing-page-card-border {
    position: relative;
    z-index: 12;
    border: 2px white solid;
    padding: 4vh 4vw;
    cursor: pointer;
    transform-style: preserve-3d;
  }

  .landing-page-card-svg {
    width: 40vw;
  }

  .landing-page-who-svg {
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .landing-page-card-border {
    position: relative;
    z-index: 12;
    cursor: pointer;
    transform-style: preserve-3d;
    margin: 12.5vw;
  }

  .landing-page-card-svg {
    width: 75vw;
  }
}
