.about-skills {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 3;
  padding: 5px 20px;
}

.about-skills-inner-container {
  margin: 5px 0px;
}

.about-button-emphasized:hover {
  cursor: pointer;
  transform: scale(1.025);
  transition: transform 0.1s ease-in-out;
}

.about-button-outlined:hover {
  cursor: pointer;
  transform: scale(1.025);
  background-color: #ffffff25;
  transition: transform 0.1s ease-in-out;
}

@media (min-width: 1200px) {
  .about-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 5;
  }
  .about-content-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: auto;
  }

  .about-description-text {
    font-family: 'Oxygen', sans-serif;
    font-weight: 500;
    font-size: 1.9vh;
  }

  .about-profile-photo-container {
    padding: 2vw;
    height: 100%;
    flex: 4;
  }

  .about-profile-photo {
    background-image: url('../../images/rishanProfileDesktop.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 35px;
    height: 100%;
    width: 100%;
  }

  .about-description {
    background-color: rgb(48, 66, 109);
    border-bottom-right-radius: 35px;
    flex: 2;
    text-align: left;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .about-description-title {
    margin: 0;
    font-size: 4vh;
  }

  .about-description-title-typing {
    color: #eb4d4b;
    font-weight: bold;
  }

  .about-skills-skill-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 5px;
  }

  .about-button-emphasized {
    background-color: #eb4d4b;
    outline-style: none;
    box-shadow: none;
    min-width: 200px;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    color: white;
    font-size: 1.6vh;
    font-family: 'Oxygen', sans-serif;
    font-weight: 700;
    margin: 0px 0px 15px;
    transform: scale(1);
    text-align: center;
    text-decoration: none;
    transition: transform 0.3s ease-in-out, background-color 0.25s ease-in-out;
  }

  .about-button-outlined {
    background-color: transparent;
    outline-style: none;
    box-shadow: none;
    padding: 10px 20px;
    border-radius: 10px;
    border: 2px white solid;
    color: white;
    font-size: 1.6vh;
    font-family: 'Oxygen', sans-serif;
    font-weight: 700;
    margin: 0px 0px 15px 0px;
    transform: scale(1);
    text-align: center;
    transition: transform 0.3s ease-in-out, background-color 0.25s ease-in-out;
  }

  .about-skills-subheading {
    text-align: left;
    margin: 0;
    border-bottom: 2px #eb4d4b solid;
    padding-bottom: 4px;
    color: #eb4d4b;
    font-size: 1.9vh;
  }

  .about-description-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media (min-width: 850px) and (max-width: 1199px) {
  .about-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
  .about-content-container {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    flex-wrap: wrap-reverse;
  }

  .about-description-text {
    font-family: 'Oxygen', sans-serif;
    font-weight: 500;
    font-size: 15px;
  }

  .about-profile-photo-container {
    padding: 20px;
    height: 100%;
    flex: 1;
  }

  .about-profile-photo {
    background-image: url('../../images/rishanProfileMobile.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 35px;
    height: 400px;
    width: 100%;
  }

  .about-description {
    text-align: left;
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .about-description-title {
    margin: 0;
    font-size: 30px;
  }

  .about-description-title-typing {
    color: #eb4d4b;
    font-weight: bold;
  }

  .about-skills-skill-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 5px;
    flex-wrap: wrap;
  }

  .about-button-emphasized {
    background-color: #eb4d4b;
    outline-style: none;
    box-shadow: none;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    color: white;
    font-size: 16px;
    font-family: 'Oxygen', sans-serif;
    font-weight: 700;
    margin: 15px 10px 15px 0px;
    flex: 1;
    transform: scale(1);
    text-align: center;
    text-decoration: none;
    transition: transform 0.3s ease-in-out, background-color 0.25s ease-in-out;
  }

  .about-button-outlined {
    background-color: transparent;
    outline-style: none;
    box-shadow: none;
    padding: 10px 20px;
    border-radius: 10px;
    border: 2px white solid;
    color: white;
    font-size: 16px;
    font-family: 'Oxygen', sans-serif;
    font-weight: 700;
    margin: 15px 0px 15px 10px;
    flex: 1;
    transform: scale(1);
    text-align: center;
    transition: transform 0.3s ease-in-out, background-color 0.25s ease-in-out;
  }

  .about-skills-subheading {
    text-align: left;
    margin: 0;
    border-bottom: 2px #eb4d4b solid;
    padding-bottom: 4px;
    color: #eb4d4b;
    font-size: 16px;
  }

  .about-description-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
}

@media (max-width: 849px) {
  .about-content-container {
    display: flex;
    flex-direction: column-reverse;
  }

  .about-description-text {
    font-family: 'Oxygen', sans-serif;
    font-weight: 500;
    font-size: 15px;
  }

  .about-profile-photo-container {
    padding: 6vw;
    height: 100%;
    flex: 4;
  }

  .about-profile-photo {
    background-image: url('../../images/rishanProfileMobile.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 35px;
    height: 400px;
    width: 100%;
  }

  .about-description {
    text-align: left;
    padding: 0px 20px;
  }

  .about-description-title {
    margin: 0;
    font-size: 30px;
  }

  .about-description-title-typing {
    color: #eb4d4b;
    font-weight: bold;
  }

  .about-skills-skill-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 5px;
    flex-wrap: wrap;
  }

  .about-button-emphasized {
    background-color: #eb4d4b;
    outline-style: none;
    box-shadow: none;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    color: white;
    font-size: 16px;
    font-family: 'Oxygen', sans-serif;
    font-weight: 700;
    margin: 15px 10px 15px 0px;
    flex: 1;
    transform: scale(1);
    text-align: center;
    text-decoration: none;
    transition: transform 0.3s ease-in-out, background-color 0.25s ease-in-out;
  }

  .about-button-outlined {
    background-color: transparent;
    outline-style: none;
    box-shadow: none;
    padding: 10px 20px;
    border-radius: 10px;
    border: 2px white solid;
    color: white;
    font-size: 16px;
    font-family: 'Oxygen', sans-serif;
    font-weight: 700;
    margin: 15px 0px 15px 10px;
    flex: 1;
    transform: scale(1);
    text-align: center;
    transition: transform 0.3s ease-in-out, background-color 0.25s ease-in-out;
  }

  .about-skills-subheading {
    text-align: left;
    margin: 0;
    border-bottom: 2px #eb4d4b solid;
    padding-bottom: 4px;
    color: #eb4d4b;
    font-size: 16px;
  }

  .about-description-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
}
