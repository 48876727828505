.workcard-main-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.workcard-company-logo-container {
  display: flex;
  margin-right: 8px;
  justify-content: center;
  align-items: center;
}

.workcard-timeline {
  position: relative;
  width: 1.5vw;
  background-color: rgb(48, 66, 109);
  margin-left: calc(3.5vw);
  margin-right: calc(3.5vw);
  display: flex;
  justify-content: center;
  align-items: center;
}

.workcard-divider {
  width: 100%;
  height: 8px;
  border: none;
  background-color: rgba(255, 255, 255, 0.25);
}

.workcard-logo-faire {
  background-image: url('../../images/faire-logo.png');
}

.workcard-logo-index-exchange {
  background-image: url('../../images/index-exchange-logo.png');
}

.workcard-logo-opal {
  background-image: url('../../images/opal-logo.png');
}

.workcard-logo-pepperdata {
  background-image: url('../../images/pepperdata-logo.png');
}

.workcard-logo-futurecom {
  background-image: url('../../images/futurecom-logo.png');
}

.workcard-logo-cfli {
  background-image: url('../../images/cfli-logo.png');
}

.workcard-logo-satec {
  background-image: url('../../images/satec-logo.png');
}

.workcard-logo-innodet {
  background-image: url('../../images/innodet-logo.png');
}

@media (min-width: 1200px) {
  .workcard-date {
    position: absolute;
    height: 8vh;
    width: 6vw;
    background-color: rgb(48, 66, 109);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Oxygen', sans-serif;
    font-weight: bold;
    font-size: 1.6vh;
    line-height: 0.9;
  }
  .workcard-inner-container {
    border-radius: 25px;
    background-color: rgb(48, 66, 109);
    display: flex;
    flex-direction: row;
    padding: 18px;
  }

  .workcard-outer-container {
    width: 100%;
    padding: 1vw;
  }

  .workcard-company-logo {
    height: 20vh;
    width: 20vh;
    border-radius: 25px;

    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: transform 0.2s ease-in-out;
  }

  .workcard-job-title {
    font-family: 'Oxygen', sans-serif;
    font-weight: bold;
    font-size: 2.5vh;
    display: flex;
    color: #eb4d4b;
  }

  .workcard-name {
    font-family: 'Oxygen', sans-serif;
    font-weight: 500;
    font-size: 2.2vh;
    color: #ecf0f1;
    text-align: left;
  }

  .workcard-description {
    font-family: 'Oxygen', sans-serif;
    font-weight: 100;
    font-size: 1.9vh;
    color: white;
    text-align: left;
  }

  .workcard-content {
    width: 100%;
    padding-left: 10px;
  }
}

@media (max-width: 1199px) {
  .workcard-date {
    position: absolute;
    height: 70px;
    width: 100px;
    background-color: rgb(48, 66, 109);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Oxygen', sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 0.9;
  }
  .workcard-inner-container {
    border-radius: 25px;
    background-color: rgb(48, 66, 109);
    display: flex;
    flex-direction: column;
    padding: 30px;
  }

  .workcard-outer-container {
    width: 100%;
    padding: 20px calc(1vw + 20px);
    padding: 20px -webkit-calc(1vw + 20px);
  }

  .workcard-company-logo {
    height: 175px;
    width: 100%;
    border-radius: 25px;

    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: transform 0.2s ease-in-out;
  }

  .workcard-job-title {
    font-family: 'Oxygen', sans-serif;
    font-weight: bold;
    font-size: 20px;
    display: flex;
    text-align: left;
    color: #eb4d4b;
  }

  .workcard-name {
    font-family: 'Oxygen', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #ecf0f1;
    text-align: left;
  }

  .workcard-description {
    font-family: 'Oxygen', sans-serif;
    font-weight: 100;
    font-size: 16px;
    color: white;
    text-align: left;
  }

  .workcard-content {
    margin-top: 20px;
    width: 100%;
  }
}
