.sidebar {
  display: flex;
  background-color: rgb(48, 66, 109);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 4vw;
  position: relative;
  z-index: 12;
}

.sidebar-icons:hover {
  background-color: #ffffff25;
}

.logo-border {
  width: 4vw !important;
  height: 4vw !important;
}

.logo-content {
  background-image: url('../../images/Logo-Red-1000px-square.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 75%;
  height: 75%;
  will-change: transform;
  transition: width 0.1s ease-in-out, height 0.1 ease-in-out;
}

@media (min-width: 1200px) {
  .icons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
    height: 35%;
    min-height: 250px;
  }
  .sidebar-icons {
    font-size: 1.5vw;
    cursor: pointer;
    width: 4vw;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    transition: background-color 0.25s ease-in-out, font-size 0.25s ease-in-out;
  }

  .sidebar-fill {
    width: 4vw;
    height: 10vh;
  }

  .sidebar-icons-selected {
    font-size: 1.8vw;
    cursor: pointer;
    width: 4vw;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #192a56;
    color: #eb4d4b;
    transition: background-color 0.25s ease-in-out, font-size 0.25s ease-in-out;
  }
}

@media (max-width: 1199px) {
  .icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    height: 35%;
    min-height: 250px;
  }
  .sidebar-icons {
    font-size: 20px;
    cursor: pointer;
    width: 100%;
    padding: 25px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1);
    color: white;
    transition: background-color 0.25s ease-in-out, transform 0.25s ease-in-out;
  }

  .sidebar-icons-selected {
    font-size: 20px;
    cursor: pointer;
    width: 100%;
    display: flex;
    padding: 25px 20px;
    justify-content: center;
    align-items: center;
    background-color: #192a56;
    transform: scale(1);
    color: #eb4d4b;
    transition: background-color 0.25s ease-in-out, transform 0.25s ease-in-out;
  }

  .sidebar-icons-text {
    font-family: 'Oxygen', sans-serif;
    font-weight: bold;
    margin-left: 15px;
  }
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 30px;
    top: 30px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #ffffffb2;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: rgb(48, 66, 109);
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #ffffff;
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: rgb(48, 66, 109);
    padding: 2.5em 0 0;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: rgb(48, 66, 109);
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
}
