* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  background-color: #192a56;
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-sidebar {
  height: 100vh;
  width: 80px;
}

.app-content {
  width: 100%;
  color: white;
  overflow: auto;
}

@media (min-width: 1200px) {
  .about-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    /* background-color: rgb(48, 66, 109); */
  }

  .projects-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    /* background-color: #192a56; */
  }

  .work-container {
    width: 100%;
    position: relative;
    /* background-color: rgb(48, 66, 109); */
  }

  .contact-container {
    width: 100%;
    height: 100vh;
    /* background-color: #192a56; */
  }

  .landing-page-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    transform-style: preserve-3d;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    background-color: rgb(48, 66, 109);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #eb4d4b;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #eb4e4bd7;
  }
}

@media (max-width: 1199px) {
  .about-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color: rgb(48, 66, 109); */
  }

  .projects-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color: #192a56; */
  }

  .work-container {
    width: 100%;
    /* background-color: rgb(48, 66, 109); */
  }

  .contact-container {
    width: 100%;
    /* background-color: #192a56; */
  }

  .landing-page-card {
    display: flex;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;
  }
}

.particles-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}
