@media (min-width: 1200px) {
  .subheading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4vw;
    background-color: rgb(48, 66, 109);
    color: #eb4d4b;
    font-size: 4vw;
    font-family: 'Jockey One', sans-serif;
  }
}

@media (max-width: 1199px) {
  .subheading-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    background-color: rgb(48, 66, 109);
    color: #eb4d4b;
    font-size: 40px;
    font-family: 'Jockey One', sans-serif;
  }
}
