@media (min-width: 1200px) {
  .work-content-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 1vw;
    overflow: auto;
  }
  .work-header-sticky {
    position: sticky;
    top: 0;
    z-index: 1002;
  }

  .work-header-relative {
    position: relative;
    z-index: 1002;
  }
}

@media (max-width: 1199px) {
  .work-content-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 1vw;
  }
}

/* .work-timeline-container {
  position: absolute;
  height: 100%;
  width: 25px;
  background-color: rgb(48, 66, 109);
  margin-top: -1vw;
  margin-bottom: -1vw;
  top: 1vw;
  left: calc(2vw + 50px);
  transform: translateX(-50%);
} */
