.card-container:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card-background-diabetes-doctor {
  background-image: url('../../images/diabetes-doctor-preview.jpeg');
  image-rendering: -webkit-optimize-contrast;
}

.card-background-chatroom {
  background-image: url('../../images/chatroom-preview.jpeg');
  image-rendering: -webkit-optimize-contrast;
}

.card-background-voice-analyzer {
  background-image: url('../../images/voice-analyzer.png');
  image-rendering: -webkit-optimize-contrast;
}

.card-background-stocker {
  background-image: url('../../images/stocker.png');
  image-rendering: -webkit-optimize-contrast;
}

.card-background-repudium {
  background-image: url('../../images/repudium.png');
  image-rendering: -webkit-optimize-contrast;
}

.card-background-mhp {
  /* background-image: url('../../images/INSERT_IMAGE_HERE') */
}

.card-background-http-web-server {
  background-image: url('../../images/http-web-server.jpg');
  image-rendering: -webkit-optimize-contrast;
}

.card-background-contacts {
  background-image: url('../../images/contacts.JPG');
  image-rendering: -webkit-optimize-contrast;
}

.card-background-launched {
  background-image: url('../../images/launched.png');
  image-rendering: -webkit-optimize-contrast;
}

.card-background-globe-display {
  background-image: url('../../images/globe-display.png');
  image-rendering: -webkit-optimize-contrast;
}

.card-background-notesense {
  background-image: url('../../images/notesense.jpeg');
  image-rendering: -webkit-optimize-contrast;
}

.card-background-annotate {
  background-image: url('../../images/annotate.png');
  image-rendering: -webkit-optimize-contrast;
}

.card-background-coming-soon {
  background-image: url('../../images/coming-soon.jpg');
  image-rendering: -webkit-optimize-contrast;
}

.card-learn-more-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform-style: preserve-3d;
  position: relative;
  transform: translateZ(0.01px);
}

.card-learn-more-container-options {
  height: 100%;
  width: 100%;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  transition: opacity 0.2s ease-in-out;
}

.card-learn-more-button {
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 9vw;
  height: 5vh;
  font-family: 'Oxygen', sans-serif;
  letter-spacing: 1px;
  font-size: 1.9vh;
  transition: background-color 0.2s ease-in-out;
}

.card-learn-more-button:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.233);
  transition: background-color 0.2s ease-in-out;
}

.card-learn-more-button-placeholder {
  height: 15px;
}

.card-mobile-icons {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 15px;
}

.card-description-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(48, 66, 109);
  top: 0px;
  bottom: 100%;
  z-index: 100;
  width: 100%;
  overflow: hidden;
  transform: translateZ(0.01px);
  transition: bottom 0.5s ease-in-out, top 1s ease-in-out;
}

.card-description-expand {
  top: -1px;
  bottom: -1%;
  transition: bottom 0.5s ease-in-out, top 0.1s ease-in-out;
}

.card-description-text {
  font-family: 'Oxygen', sans-serif;
  font-size: 1.9vh;
  font-weight: 100;
  margin: 50px;
  line-height: 3vh;
  text-align: left;
}

@media (min-width: 1200px) {
  /* common */
  .ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    z-index: 10;
    pointer-events: none;
    transform: translateZ(0.02px);
  }

  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #ca9f28;
    margin-right: 12px;
    margin-bottom: 12px;
  }
  .ribbon a {
    position: absolute;
    display: block;
    width: 225px;
    padding: 10px 20px 10px 10px;
    background-color: #e1b12c;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    font: 700 18px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
    pointer-events: auto;
    text-decoration: none;
  }

  .ribbon a:hover {
    cursor: pointer;
  }

  /* top left*/
  .ribbon-top-left {
    top: -10px;
    left: -10px;
  }
  .ribbon-top-left::before,
  .ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-left::before {
    top: 0;
    right: 0;
  }
  .ribbon-top-left::after {
    bottom: 0;
    left: 0;
  }
  .ribbon-top-left a {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
  }

  /* top right*/
  .ribbon-top-right {
    top: -10px;
    right: -10px;
  }
  .ribbon-top-right::before,
  .ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
  }
  .ribbon-top-right::before {
    top: 0;
    left: 0;
  }
  .ribbon-top-right::after {
    bottom: 0;
    right: 0;
  }
  .ribbon-top-right a {
    left: -25px;
    top: 30px;
    transform: rotate(45deg);
  }

  /* bottom left*/
  .ribbon-bottom-left {
    bottom: -10px;
    left: -10px;
  }
  .ribbon-bottom-left::before,
  .ribbon-bottom-left::after {
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-bottom-left::before {
    bottom: 0;
    right: 0;
  }
  .ribbon-bottom-left::after {
    top: 0;
    left: 0;
  }
  .ribbon-bottom-left a {
    right: -25px;
    bottom: 30px;
    transform: rotate(225deg);
  }

  /* bottom right*/
  .ribbon-bottom-right {
    bottom: -10px;
    right: -10px;
  }
  .ribbon-bottom-right::before,
  .ribbon-bottom-right::after {
    border-bottom-color: transparent;
    border-right-color: transparent;
  }
  .ribbon-bottom-right::before {
    bottom: 0;
    left: 0;
  }
  .ribbon-bottom-right::after {
    top: 0;
    right: 0;
  }
  .ribbon-bottom-right a {
    left: -25px;
    bottom: 30px;
    transform: rotate(-225deg);
  }
  .card-learn-more-icon {
    margin-left: 6px;
    font-size: 1.9vh;
    width: 1.9vh;
    height: 1.9vh;
  }
  .card-tech-stack-icon-img {
    width: 2vh;
    height: 2vh;
    margin: 0px 10px;
    transform: translateZ(15px);
    object-fit: 'contain';
    image-rendering: -webkit-optimize-contrast;
  }
  .card-description-close {
    position: absolute;
    top: 20px;
    right: 15px;
    font-size: 2.2vh;
    cursor: pointer;
  }
  .card-title-container {
    position: relative;
    background-color: rgb(48, 66, 109);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    height: 4.5vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transform-style: preserve-3d;
  }
  .card-tech-stack-icon {
    font-size: 2vh;
    margin: 0px 10px;
    transform: translateZ(15px);
  }
  .card-container {
    position: 'relative';
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    border-radius: 25px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 3px 5px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transform-style: preserve-3d;
  }
  .card-title {
    position: absolute;
    font-size: 5.5vh;
    font-family: 'Jockey One', sans-serif;
    /* -webkit-text-stroke: 2px #192a56; */
    transform: translateZ(25px);
    color: white;
  }

  .card-title-drop {
    position: absolute;
    font-size: 5.5vh;
    font-family: 'Jockey One', sans-serif;
    /* -webkit-text-stroke: 2px #192a56; */
    transform: translateY(0.5px) translateZ(0.2px);
    color: rgb(48, 66, 109);
  }

  .card-learn-more-button-link {
    color: white;
    text-decoration: none;
  }

  .card-learn-more-container-options:hover {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
  .card-tech-stack-container {
    height: 4.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: rgb(48, 66, 109);
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }
}

@media (max-width: 1199px) {
  /* common */
  .ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    z-index: 10;
    pointer-events: none;
    transform: translateZ(0.02px);
  }

  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #ca9f28;
    margin-right: 25px;
    margin-bottom: 25px;
  }

  .ribbon::before {
    transform: translateY(20);
  }
  .ribbon a {
    position: absolute;
    display: block;
    width: 225px;
    padding: 5px 25px 5px 5px;
    background-color: #e1b12c;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    font: 700 18px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
    pointer-events: auto;
    text-decoration: none;
  }

  .ribbon a:hover {
    cursor: pointer;
  }

  /* top left*/
  .ribbon-top-left {
    top: -10px;
    left: -10px;
  }
  .ribbon-top-left::before,
  .ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-left::before {
    top: 0;
    right: 0;
  }
  .ribbon-top-left::after {
    bottom: 0;
    left: 0;
  }
  .ribbon-top-left a {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
  }

  /* top right*/
  .ribbon-top-right {
    top: -10px;
    right: -10px;
  }
  .ribbon-top-right::before,
  .ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
  }
  .ribbon-top-right::before {
    top: 0;
    left: 0;
  }
  .ribbon-top-right::after {
    bottom: 0;
    right: 0;
  }
  .ribbon-top-right a {
    left: -25px;
    top: 30px;
    transform: rotate(45deg);
  }

  /* bottom left*/
  .ribbon-bottom-left {
    bottom: -10px;
    left: -10px;
  }
  .ribbon-bottom-left::before,
  .ribbon-bottom-left::after {
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-bottom-left::before {
    bottom: 0;
    right: 0;
  }
  .ribbon-bottom-left::after {
    top: 0;
    left: 0;
  }
  .ribbon-bottom-left a {
    right: -25px;
    bottom: 30px;
    transform: rotate(225deg);
  }

  /* bottom right*/
  .ribbon-bottom-right {
    bottom: -10px;
    right: -10px;
  }
  .ribbon-bottom-right::before,
  .ribbon-bottom-right::after {
    border-bottom-color: transparent;
    border-right-color: transparent;
  }
  .ribbon-bottom-right::before {
    bottom: 0;
    left: 0;
  }
  .ribbon-bottom-right::after {
    top: 0;
    right: 0;
  }
  .ribbon-bottom-right a {
    left: -25px;
    bottom: 30px;
    transform: rotate(-225deg);
  }
  .card-learn-more-icon {
    margin-left: 6px;
    font-size: 22px;
    width: 22px;
    height: 22px;
  }
  .card-tech-stack-icon-img {
    width: 20px;
    height: 20px;
    margin: 0px 10px;
    transform: translateZ(15px);
    object-fit: 'contain';
    image-rendering: -webkit-optimize-contrast;
  }
  .card-description-close {
    position: absolute;
    top: 20px;
    right: 15px;
    font-size: 22px;
    cursor: pointer;
  }
  .card-tech-stack-container {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: rgb(48, 66, 109);
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  .card-title-container {
    position: relative;
    background-color: rgb(48, 66, 109);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transform-style: preserve-3d;
  }
  .card-container {
    position: 'relative';
    display: flex;
    flex-direction: column;
    height: 400px;
    width: 100%;
    border-radius: 25px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 3px 5px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transform-style: preserve-3d;
  }
  .card-title {
    position: absolute;
    font-size: 40px;
    font-family: 'Jockey One', sans-serif;
    /* -webkit-text-stroke: 2px #192a56; */
    transform: translateZ(25px);
    top: 10px;
    color: white;
    pointer-events: none;
  }
  .card-tech-stack-icon {
    font-size: 20px;
    margin: 0px 10px;
    transform: translateZ(15px);
  }

  .card-title-drop {
    position: absolute;
    font-size: 40px;
    font-family: 'Jockey One', sans-serif;
    /* -webkit-text-stroke: 2px #192a56; */
    transform: translateX(4px) translateY(12px) translateZ(0.2px);
    color: rgb(48, 66, 109);
    pointer-events: none;
  }

  .card-learn-more-button-link {
    color: white;
    text-decoration: none;
    height: 22px;
    width: 22px;
    margin: 0px 4px;
  }
}
