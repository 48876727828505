.projects-component-container {
  height: 100%;
}

.card-container-padding {
  padding: 1vw;
}

.projects-swipable-container {
  height: 100%;
  width: 100%;
  position: relative;
  transition: transform 0.3s ease-in-out;
  cursor: grab;
}

.projects-swipable-views-container {
  height: -webkit-calc(100% - 4vw);
  height: calc(100% - 4vw);
  width: 100%;
}

.projects-carousel-container {
  margin: 20px 0px;
}

.projects-go-left:hover,
.projects-go-right:hover {
  cursor: pointer;
}

.projects-go-animate {
  animation: scaling 0.5s infinite alternate ease-in-out;
}

@keyframes scaling {
  from {
    transform: scale(1) translateY(-50%);
  }
  to {
    transform: scale(1.2) translateY(-50%);
  }
}

@media (min-width: 1200px) {
  .projects-go-left {
    position: absolute;
    left: 20px;
    top: calc(50% + 2vw);
    top: -webkit-calc(50% + 2vw);
    transform: translateY(-50%);
    z-index: 1000;
    color: #eb4d4b;
    font-size: 6vh;
  }
  .projects-go-right {
    position: absolute;
    right: 20px;
    top: calc(50% + 2vw);
    top: -webkit-calc(50% + 2vw);
    transform: translateY(-50%);
    z-index: 1000;
    color: #eb4d4b;
    font-size: 6vh;
  }
  .projects-page-container {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    padding: 1vw;
    overflow: hidden;
  }

  .react-swipeable-view-container {
    width: calc(96vw - 15px);
    width: -webkit-calc(96vw - 15px);
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .projects-go-left {
    position: absolute;
    left: 20px;
    top: calc(50% + 2vw);
    top: -webkit-calc(50% + 2vw);
    transform: translateY(-50%);
    z-index: 1000;
    color: #eb4d4b;
    font-size: 6vh;
  }
  .projects-go-right {
    position: absolute;
    right: 20px;
    top: calc(50% + 2vw);
    top: -webkit-calc(50% + 2vw);
    transform: translateY(-50%);
    z-index: 1000;
    color: #eb4d4b;
    font-size: 6vh;
  }
  .projects-page-container {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    padding: 1vw;
    overflow: hidden;
  }

  .react-swipeable-view-container {
    width: calc(96vw - 15px);
    width: -webkit-calc(96vw - 15px);
  }
}

@media (max-width: 1199px) {
  .projects-go-left {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    color: #eb4d4b;
    font-size: 50px;
  }
  .projects-go-right {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    color: #eb4d4b;
    font-size: 50px;
  }
  .projects-page-container {
    height: 100%;
    display: grid;
    padding: 1vw;
    overflow: hidden;
  }
}
