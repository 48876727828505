.contact-content-container {
  display: flex;
  flex-direction: column;
  height: -webkit-calc(100% - 4vw);
  height: calc(100% - 4vw);
  justify-content: center;
  align-items: center;
}

.contact-input::placeholder {
  color: white;
  opacity: 0.5;
}

.contact-button:hover {
  cursor: pointer;
  transform: scale(1.025);
  transition: transform 0.1s ease-in-out;
}

.contact-social-icon:hover {
  background-color: #ffffff25;
  transition: background-color 0.1s ease-in-out;
}

@media (min-width: 1200px) {
  .contact-loading {
    height: 3vh;
    width: 3vh;
  }
  .contact-input {
    width: 100%;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 12px;
    padding: 15px;
    font-size: 1.9vh;
    color: white;
    outline-style: none;
    box-shadow: none;
    margin: 15px 0px;
    font-family: 'Oxygen', sans-serif;
  }
  .contact-button {
    width: 100%;
    background-color: #eb4d4b;
    outline-style: none;
    box-shadow: none;
    padding: 15px;
    border-radius: 12px;
    border: none;
    color: white;
    font-size: 1.9vh;
    font-family: 'Oxygen', sans-serif;
    font-weight: 700;
    margin: 15px 0px;
    transform: scale(1);
    transition: transform 0.3s ease-in-out, background-color 0.25s ease-in-out;
  }
  .contact-form-container {
    width: 60%;
    border: 1px solid white;
    padding: 4vh;
    border-radius: 25px;
    border-color: #ffffff40;
    border-width: 8px;
    margin-top: 50px;
  }

  .contact-socials {
    padding: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 60%;
  }

  .contact-social-icon {
    border: 1px white solid;
    border-radius: 3vh;
    background-color: 'transparent';
    transition: background-color 0.1s ease-in-out;
  }
}

@media (max-width: 1199px) {
  .contact-loading {
    height: 25px;
    width: 25px;
  }
  .contact-button {
    width: 100%;
    background-color: #eb4d4b;
    outline-style: none;
    box-shadow: none;
    padding: 15px;
    border-radius: 12px;
    border: none;
    color: white;
    font-size: 18px;
    font-family: 'Oxygen', sans-serif;
    font-weight: 700;
    margin: 15px 0px;
    transform: scale(1);
    transition: transform 0.3s ease-in-out, background-color 0.25s ease-in-out;
  }
  .contact-input {
    width: 100%;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 12px;
    padding: 15px;
    font-size: 16px;
    color: white;
    outline-style: none;
    box-shadow: none;
    margin: 15px 0px;
    font-family: 'Oxygen', sans-serif;
  }
  .contact-form-container {
    padding: 0px 6vw;
    margin-top: 12.5px;
  }

  .contact-socials {
    padding-top: 12.5px;
    padding-bottom: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }

  .contact-social-icon {
    border: 1px white solid;
    border-radius: 20px;
    background-color: 'transparent';
    transition: background-color 0.1s ease-in-out;
  }
}
